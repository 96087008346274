export enum HTTP_METHOD {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete'
}

export type User = {
    email: string
    emailStatus?: string
    firstName: string
    lastName: string
    role: string
    userID?: string
    password?: string
    statusCode?: string
}

export type Login = {
    email: string
    password: string
}