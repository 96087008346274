import { Notification, NotificationsState } from "./notifications"
import { ACTION_TYPES } from "./actions"
import { Buffer } from "buffer";

export const defaultState: Array<Notification> = []

export const notificationsReducer = (
    state: NotificationsState,
    action: { type: ACTION_TYPES, payload?: any }
) => {
    switch (action.type) {
        case ACTION_TYPES.ADD_NOTIFICATION:
            return [...state, {...action.payload, id: Buffer.from(new Date().toString() + action.type + action.payload).toString('base64')}]
        case ACTION_TYPES.REMOVE_NOTIFICATION:
            return state.filter((notification) => notification.id != action.payload)
        default:
            return
    }
}
