import React from "react";
import { UserProvider } from "./src/context/User";
import { NotificationsProvider } from "./src/context/Notifications"

export const wrapRootElement = ({ element }) => (
  <NotificationsProvider>
    <UserProvider>
      {element}
    </UserProvider>
  </NotificationsProvider>
);

export const wrapPageElement = ({ element, props }, pluginOptions) => {
  // console.log(element, props);
};
