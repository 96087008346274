import { HTTP_METHOD } from "../global.d"

class APIBaseClient {
    protected rootUrl: string = process.env.GATSBY_AUTH_API_ROOT_URL
    protected baseEndpoint: string

    protected call = async (endpoint: string, method: HTTP_METHOD, body: any = {}, token = ""): Promise<any> => {
        let options = {
            method: method,
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        }

        if (body && (method == HTTP_METHOD.POST || method == HTTP_METHOD.PUT || method == HTTP_METHOD.DELETE)) {
            options.body = JSON.stringify(body)
        }
        try {
            const res = await fetch(`${this.rootUrl}/${this.baseEndpoint}/${endpoint}`, options)

            switch (res.status) {
                case 200:
                    break;
                case 201:
                    break;
                case 204:
                    return {};
                case 401:
                    throw new Error("Your request is unauthorized. Please log out, sign in, and try again.");
                default:
                    const msg = await res.json()
                    if (msg.title != null) {
                        throw new Error(msg.title)
                    } else {
                        throw new Error("Response not okay.")
                    }
            }

            const json = await res.json()
            return json
        } catch (error) {
            throw error
        }
    }
}

export default APIBaseClient