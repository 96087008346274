import { HTTP_METHOD, Login, User } from "../global.d";
import { isBrowser } from "../helpers";
import APIBaseClient from "./APIBase";
import { AuthResponse } from "./Auth.d";
import { Buffer } from "buffer";

enum ENDPOINTS {
  LOGIN = "login",
  USERS = "users",
}

class AuthClient extends APIBaseClient {
  private localStorageItemName = "alexAdminUser";

  constructor() {
    super();
    this.baseEndpoint = "auth";
  }

  public login = async (login: Login): Promise<User> => {
    try {
      const json = await this.call(ENDPOINTS.LOGIN, HTTP_METHOD.POST, login);

      if (!json.user.role.includes("Admin")) {
        throw new Error("This user is not an administrator.");
      }

      this.setUser(json);

      return json;
    } catch (error) {
      if (error.message === "This user is not an administrator.") {
        throw new Error("This user is not authorized. Please contact your administrator.");
      } else {
        throw new Error("Invalid username or password. Please try again.");
      }
    }
  };

  public logout = (callback?: () => void): void => {
    this.setUser(null);
    localStorage.removeItem("activeEventId");
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    localStorage.removeItem("visitorType");
    callback && callback();
  };

  public isLoggedIn = (): boolean => {
    const user = this.getUser();
    return user && Object.hasOwn(user, "email");
  };

  public checkSessionExpired = () => {
    try {
      const user = this.getUser();
      const payload = user?.tokenPayload?.access_token.split(".")[1];
      const decoded = Buffer.from(payload, "base64").toString();
      const json = JSON.parse(decoded);
      let expirationDate: Date = new Date(0);
      expirationDate.setUTCSeconds(json.exp);
      return expirationDate.valueOf() < new Date().valueOf();
    } catch (err) {
      throw err;
    }
  };

  public getUser = () => {
    return isBrowser() && window.localStorage.getItem(this.localStorageItemName)
      ? JSON.parse(window.localStorage.getItem(this.localStorageItemName))
      : null;
  };

  private setUser = (user: AuthResponse) => {
    isBrowser() && window.localStorage.setItem(this.localStorageItemName, JSON.stringify(user));
    return true;
  };

  public update = async (user: AuthResponse) => {
    try {
      const token = user.tokenPayload?.access_token;
      const res = await this.call(
        `${ENDPOINTS.USERS}/${user.user.userID}`,
        HTTP_METHOD.PUT,
        user.user,
        token
      );
      const updatedUser = {
        ...user,
        user: { ...res },
      };
      this.setUser(updatedUser);
      return res;
    } catch (err) {
      throw err;
    }
  };

  public resetPassword = async (user: AuthResponse, userID: string, password: string) => {
    try {
      const token = user.tokenPayload?.access_token;
      const res = await this.call(
        `${ENDPOINTS.USERS}/${userID}/password`,
        HTTP_METHOD.PUT,
        { password },
        token
      );
      return res;
    } catch (err) {
      // throw err;
    }
  };

  public updateEmail = async (user: AuthResponse) => {
    try {
      const token = user.tokenPayload?.access_token;
      const res = await this.call(
        `${ENDPOINTS.USERS}/${user.user.userID}/email`,
        HTTP_METHOD.PUT,
        user.user,
        token
      );
      const updatedUser = {
        ...user,
        user: { ...res },
      };
      this.setUser(updatedUser);
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export default AuthClient;
