import numeral from "numeral"

export const isBrowser = (): boolean => typeof window !== "undefined"

export const dateToFormattedString = (date: Date): string => {
    return date.toISOString().split('T')[0]
}

export const timeoutValues = [10000, 20000, 30000, 45000, 60000, 90000, 120000, 180000]
export const knownBadgeimeoutValues = [300000, 600000, 900000, 1200000, 1500000, 1800000, 3600000, 7200000, 14400000, 28800000, 43200000, 86400000]

export const bytesToFilesize = (bytes: number) => {
    return numeral(bytes).format('0.0b')
}

export const camelToTitleCase = (value: string) => {
    const result = value.replace(/([A-Z])/g, " $1")
    const title = result.charAt(0).toUpperCase() + result.slice(1)
    return title.replace('I D', 'ID')
}