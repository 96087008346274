import React, { useState, createContext, useReducer } from 'react'
import { Notification, NotificationsState } from './notifications'
import { defaultState, notificationsReducer } from './reducers'

export const NotificationsContext = createContext<[Array<Notification>, React.Dispatch<any>]>([ 
    defaultState, (value: any) => {} 
])

export const NotificationsProvider: React.FC<NotificationsState> = ({ children }) => {
    const [notifications, dispatch] = useReducer(notificationsReducer, defaultState)

    return (
        <NotificationsContext.Provider value={[notifications, dispatch]}>
            {children}
        </NotificationsContext.Provider>
    )
}
