import { Notification, NotificationType } from "./notifications"

export enum ACTION_TYPES {
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION
}

export const addNotification = (notification: Notification) => ({
    type: ACTION_TYPES.ADD_NOTIFICATION,
    payload: notification
})

export const removeNotification = (id: number) => ({
    type: ACTION_TYPES.REMOVE_NOTIFICATION,
    payload: id
})

