import { createContext, useMemo, useState } from "react";
import { UserProviderProps, UserState } from "./user.d";
import { navigate } from "gatsby";
import { Login, User } from "../../global";
import AuthClient from "../../clients/Auth";

export const UserContext = createContext<UserState>({
  user: null,
  logout: null,
  isLoggedIn: null,
  setIsLoggedIn: null,
  login: null,
  checkSessionExpired: null
});

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const client = new AuthClient();
  const user = client.getUser();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    user && Object.hasOwn(user, "user")
  );

  const login = async ({ email, password }: Login): Promise<User> => {
    try {
      const client = new AuthClient();
      const user = await client.login({ email, password });
      if (user) {
        setIsLoggedIn(true);
      }
      return user;
    } catch (error) {
      throw error;
    }
  };

  const checkSessionExpired = (): boolean => {
    try {
      const isExpired = client.checkSessionExpired()
      return isExpired
    } catch(err) {
      // console.log(err)
      return true
    }
  }

  const logout = () =>
    client.logout(() => {
      setIsLoggedIn(false);
      navigate("/login");
    });

  return (
    <UserContext.Provider
      value={{ user, login, logout, isLoggedIn, setIsLoggedIn, checkSessionExpired }}
    >
      {children}
    </UserContext.Provider>
  );
};
